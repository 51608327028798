import React, { Component } from 'react';
import { Row, Col, Typography, Form, Input, Button, notification, Checkbox, Avatar, Result } from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Redirect, Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Core from "../../Core";
import styles from './index.scss';

const { Title } = Typography;

export default class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      formSuccess: false,
      redirectLogin: false
    };
    this.onFinish = this.onFinish.bind(this);
    this.onFinishFailed = this.onFinishFailed.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  onFinish(values) {
    const fail = (extraInfo = '') => {
      const description = `Erro ao efetuar registo. ${extraInfo}`
      console.log(extraInfo);
      notification.error({
        message: 'Registo',
        description: <div dangerouslySetInnerHTML={{ __html: description }}></div>,
        top: 200
      });

      ReactGA.event({
        category: Core.data().config.site_title,
        action: `Register`,
        label: `Unsuccessful`
      });

      this.setState({ loading: false, error: true });
    };
    this.setState({ loading: true });
    Core.apiService(`register`, {
      data: {
        name: values.name,
        mail: values.mail.toLowerCase(),
        metadata: {
          surname: values.surname,
          job: values.job,
          expertise: values.expertise,
          place: values.place,
        }
      },
      onSuccess: (data) => {
        if (data.json && data.json['result'] == true) {
          this.setState({ loading: false, formSuccess: true });
          notification.success({
            message: 'Sucesso',
            description: 'Registo efetuado com sucesso.'
          });
          setTimeout(function () {
            this.setState({ redirectLogin: true });
          }.bind(this), 2000);

          ReactGA.event({
            category: Core.data().config.site_title,
            action: `Register`,
            label: `${data.json.name}`
          });
        } else if (data.json && data.json['result'] == false) {
          if (data.json['already_exist']) {
            fail(`O email já se encontra registado. Por favor, faça <a href="/login">login</a>.`);
          } else {
            fail(data.json['msg']);
          }
        } else {
          console.log("Register data is not a JSON!");
          fail();
        }
      },
      onFail: (data) => {
        this.setState({ loadingTable: true });
        console.log("Register data failed.", data);
        fail();
      }
    });
  };

  onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  }

  render() {
    const { redirectLogin, loading, formSuccess } = this.state;

    if (redirectLogin) {
      return <Redirect to="/" />;
    }

    const layout = {
      labelCol: { offset: 2, span: 20 },
      wrapperCol: { offset: 2, span: 20 },
    };
    const tailLayout = {
      wrapperCol: { offset: 10, span: 12 },
    };

    return (
      <div className="register">
        <div className="register--form">

          {(formSuccess ?
            <Result
              status="success"
              title="Obrigado pelo seu registo."
              subTitle="Brevemente será redirecionado para a homepage."
            />
            :
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Form.Item label="&nbsp;	 CRIAR CONTA">
                <Input.Group className="group-input">
                  <Form.Item
                    className="center-input"
                    name="mail"
                    rules={[{ required: true, message: 'Insira um Email válido.', pattern: new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,8}$") }]}
                  >
                    <Input placeholder="Endereço de email *" />
                  </Form.Item>
                  <Form.Item
                    className="center-input"
                    name="mail-confirmation"
                    dependencies={['mail']}
                    rules={[
                      {
                        required: true,
                        message: 'Insira novamente o seu email',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('mail') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Os dois emails que inseriu, não são iguais!'));
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="Confirmar endereço de email *" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>

              <Form.Item label="&nbsp; INFORMAÇÃO PESSOAL">
                <Input.Group compact>
                  <Form.Item
                    className="center-input"
                    name="name"
                    noStyle
                    rules={[{ required: true, message: 'Insira o seu Nome.' }]}
                  >
                    <Input style={{ width: '50%' }} placeholder="Nome *" />
                  </Form.Item>
                  <Form.Item
                    className="center-input"
                    name="surname"
                    noStyle
                    rules={[{ required: true, message: 'Insira o seu Apelido.' }]}
                  >
                    <Input style={{ width: '49%' }} placeholder="Apelido *" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>

              <Form.Item label="&nbsp;	INFORMAÇÃO PROFISSIONAL" className="professional-data">
                <Input.Group compact>
                  <Form.Item
                    className="center-input"
                    name="job"
                    noStyle
                    rules={[{ required: true, message: 'Insira a sua profissão.' }]}
                  >
                    <Input style={{ width: '50%' }} placeholder="Profissão *" />
                  </Form.Item>
                  <Form.Item
                    className="center-input"
                    name="expertise"
                    noStyle
                    rules={[{ required: true, message: 'Insira o seu especialidade.' }]}
                  >
                    <Input style={{ width: '49%' }} placeholder="Especialidade *" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item className="professional-data">
                <Input.Group>
                  <Form.Item
                    className="center-input"
                    name="place"
                    rules={[{ required: true, message: 'Insira o seu local de trabalho.' }]}
                  >
                    <Input style={{ width: '100%' }} placeholder="Local de trabalho *" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>

              {/* <Form.Item className="register__checkbox"
                className="center-input"
                name="checkbox"
                valuePropName="checked"
                rules={[{
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject('É necessário aceitar!'),
                }]}
              >
                <Checkbox>
                  <b>Autorizo</b> <br /> Autorizo o tratamento dos meus dados pessoais pela You Are Live, em conformidade com a legislação em vigor, os termos e condições aplicáveis e a nossa politica de privacidade, de acordo com a natureza, âmbito, finalidade e objeto dos serviços por nós disponibilizados e aos quais aderi.
                </Checkbox>
              </Form.Item> */}
              <Form.Item {...tailLayout} className="register__form-action">
                <Button className="submit-button" type="primary" htmlType="submit" loading={loading}>
                  Registar
                      </Button>
              </Form.Item>
            </Form>
          )}


        </div>
        <div className="register--image">
          <img alt="logo-register" className="logo-register" src="/data/images/logo-alt.png" />
        </div>

      </div>

    );
  }
}
