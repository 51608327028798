import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import { Row, Col, Typography, Spin, Form, Input, Checkbox, Button, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';
import sha512 from 'js-sha512';

import Core from '../../Core';
import RecoverModal from './RecoverModal/index.jsx';
import styles from "./index.scss";

const { Title } = Typography;

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            counter: 0,
            loginOutput: null,
            error: false,
            visibleModal: false
        };
        this.login = this.login.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        if (this.props.token) {
            this.login(this.props.token);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleClick(event) {
        this.setState({
            visibleModal: !this.state.visibleModal
        });
    }

    login(hash, values) {
        this.setState({ loading: true });
        const fail = () => {
            this.setState({ loading: false });
            notification.error({
                message: 'Login',
                description: 'Acesso recusado. Por favor contacte o Secretariado do Encontro para mais informações: 17enai@addsolutions.pt',
                top: 200,
                duration: 9
            });
            ReactGA.event({
                category: Core.data().config.site_title,
                action: `Login`,
                label: `Unsuccessful`
            });
        };
        Core.apiData(`login/${hash}.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    Core.data().hash = hash;
                    Core.data().arena = data.json.arena;
                    Core.data().login = data.json;
                    sessionStorage.setItem("data", JSON.stringify(Core.data()));
                    const { onLoggedIn } = this.props;
                    if (onLoggedIn) {
                        onLoggedIn(Core.data());
                    }
                    this.setState({ loading: false });

                    ReactGA.event({
                        category: Core.data().config.site_title,
                        action: `Login`,
                        label: `${Core.data().login.name} (${Core.data().login.hid.substring(0, 3)})`
                    });

                } else {
                    //this.setState({ error: true });
                    //console.log("Login data is not a JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                //this.setState({ error: true });
                //console.log("Login data failed.", data);
                if (Core.data().config.email_only) {
                    Core.apiService(`register`, {
                        data: {
                            mail: values.mail.toLowerCase()
                        },
                        onSuccess: (data) => {
                            if (data.json) {
                                Core.apiData(`login/${hash}.json`, {
                                    onSuccess: (data) => {
                                        if (data.json) {
                                            Core.data().hash = hash;
                                            Core.data().arena = data.json.arena;
                                            Core.data().login = data.json;
                                            sessionStorage.setItem("data", JSON.stringify(Core.data()));
                                            const { onLoggedIn } = this.props;
                                            if (onLoggedIn) {
                                                onLoggedIn(Core.data());
                                            }
                                        } else {
                                            fail();
                                        }
                                    }
                                });
                            } else {
                                console.log("Register data is not a JSON!");
                                fail();
                            }
                        },
                        onFail: (data) => {
                            console.log("Register data failed.", data);
                            fail();
                        }
                    });
                } else {
                    fail();
                }
            }
        });
    }

    onFinish(values) {
        const hash = sha512(Core.data().config.no_password || Core.data().config.email_only ? values.mail.toLowerCase() : values.mail.toLowerCase() + ':' + values.password);
        if (values.remember) {
            sessionStorage.setItem('mail', values.mail.toLowerCase());
            sessionStorage.setItem('password', values.password);
        }
        this.login(hash, values);
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    render() {
        const { loading, counter, visibleModal } = this.state;
        if (loading) {
            return (<div className="login"><Spin /></div>);
        }
        const layout = {
            labelCol: { offset: 2, span: 20 },
            wrapperCol: { offset: 2, span: 20 },
        };
        const tailLayout = {
            wrapperCol: { offset: 2, span: 20 },
        };

        return (
            <div className="login">
                <div className="login--form">
                    <label>&nbsp;	 Inicie sessão para aceder ao evento:</label>
                    <Form
                        name="basic"
                        className="form"
                        layout="inline"
                        initialValues={{
                            mail: sessionStorage.getItem("mail"),
                            password: sessionStorage.getItem("password"),
                            remember: true
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            className="center-input email"
                            name="mail"
                            rules={[{ required: true, type: 'email', message: 'Por favor insira o seu endereço de email.' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Endereço de email" />
                        </Form.Item>

                        <Form.Item {...tailLayout} className="action">
                            <Button className="submit-button enter" type="primary" htmlType="submit">
                                ENTRAR
                            </Button>
                        </Form.Item>
                        


                    </Form>
                    <a className="register-link" href="./registo">Ainda não tem registo?</a>


                </div>
                <div className="login--image">
                    <img alt="logo-login" className="logo-login" src="/data/images/logo-alt.png" />
                </div>
            </div>

        );

    }
}
