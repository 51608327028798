import React, { Component } from "react";
import ReactDOM from "react-dom";

import { Layout, Menu, Spin } from 'antd';
import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';
import classNames from 'classnames';

import MainFooter from "./containers/Footer";
import { Redirect } from 'react-router-dom';

import MainPage from './pages/Main';
import TermsConditionPage from './pages/TermsCondition';
import PrivacyPoliciesPage from './pages/PrivacyPolicies';
import CookiesPoliciesPage from './pages/CookiesPolicies';
import SpeakersPage from './pages/Speakers';
import PlayerPage from './pages/Player';
import RegisterPage from './pages/Register';
import SchedulePage from './pages/Schedule';
import LoginPage from './pages/Login';
import LivePollResults from './pages/LivePollResults';

import Cookies from './containers/Cookies';
import GA from './components/Utils/GoogleAnalytics.js'

import Core from './Core';
import { getSocket, initSocket } from './socket';


import './App.scss';

import { UserOutlined } from '@ant-design/icons';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loginLogoutTick: 0,
      defaultSelectedMenu: 'main',
      burgerMenu: false,
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.handleLogged = this.handleLogged.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    Core.onLogged = this.handleLogged;
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (window.location.pathname == '/') {
      this.setState({ defaultSelectedMenu: 'main' });
    } else {
      this.setState({ defaultSelectedMenu: 'none' });
    }
    if (Core.data().login && getSocket() == null) {
      initSocket(Core.data().login);
    }
    Core.menu.click = this.onMenuClick;
    this.loadConfig();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(window.location.pathname.toUpperCase());
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    Core.menu.click = () => { };
  }


  updateWindowDimensions() {
    const { burgerMenu } = this.state;
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      burgerMenu: window.innerWidth > 800 ? false : burgerMenu
    });
  }

  onMenuClick(selectedMenu) {
    let { defaultSelectedMenu } = this.state;
    if (selectedMenu) {
      defaultSelectedMenu = selectedMenu;
    }
    this.setState({ burgerMenu: false, defaultSelectedMenu });
    window.scrollTo(0, 0);
  }


  loadConfig() {
    Core.apiData(`config.json`, {
      onSuccess: (data) => {
        if (data.json) {
          Core.data().config = data.json;
          document.title = data.json.site_title;
          this.setState({ loaded: true });
        } else {
          this.setState({ loaded: false });
          console.log("Config data is not a JSON!", data);
        }
      },
      onFail: (data) => {
        this.setState({ loaded: false });
        console.log("Config data failed!", data);
      }
    });
  }

  handleLogged() {
    const { loginLogoutTick } = this.state;
    this.setState({ loginLogoutTick: loginLogoutTick + 1 });
  }

  handleLogout() {
    const { loginLogoutTick } = this.state;
    Core.data().login = null;
    sessionStorage.clear();
    this.setState({ loginLogoutTick: loginLogoutTick + 1 });
    window.location.assign("/")
  }

  render() {
    const { burgerMenu, defaultSelectedMenu, loaded, logout } = this.state;
    if (loaded == false) {
      return (
        <Layout>
          <div className="loading">
            <Spin size="large" />
            <h1>A carregar...</h1>
          </div>
        </Layout>
      );
    }

    console.log("Core Data: ");
    console.log(Core.data());

    return (
      <Router>
        { GA.init() && <GA.RouteTracker />}
        <div className="page">
          <Layout>
            <Content>
              <Switch>
                <Route path="/termos-e-condicoes" component={TermsConditionPage} />
                <Route path="/politica-de-privacidade" component={PrivacyPoliciesPage} />
                <Route path="/politica-de-cookies" component={CookiesPoliciesPage} />
                {Core.data().config.speakers == true ?
                  <Route path="/speakers" component={SpeakersPage} />
                  : null}
                <Route path="/player" component={PlayerPage} />
                {Core.data().config.register == true ?
                  <Route path="/registo" component={RegisterPage} />
                  : null}
                {Core.data().config.schedules == true ?
                  <Route path="/programa" component={SchedulePage} />
                  : null}
                <Route path="/livePollResults" component={LivePollResults} />
                <Route path="/login/:token?" component={LoginPage} />
                <Route path="/" component={LoginPage} />
              </Switch>
            </Content>
          </Layout>
        </div>
      </Router>
    );
  }
}
